import React from "react";
import { useTranslation } from "react-i18next";

function FaqSectionItem(props) {
  const { t } = useTranslation();

  const content = (  
       <>    
      <p>
        <strong>{props.title}</strong> {props.description}
        {(props.link && 
            <span>
               &nbsp;<a href={props.link} target="_blank" rel="noopener noreferrer">&raquo; {t('faq.mapslink')} &laquo;</a>
            </span>
            )
          }
      </p> 
      </>
  );

  return (
      <div>{content}</div>
  );
  
}

export default FaqSectionItem;