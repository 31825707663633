import React, { useState, useEffect } from 'react';
import classes from './Form.module.css';
import { useTranslation } from "react-i18next";

function Participant(props) {
  const [name, setName] = useState(props.name);
  const [veggie, setVeggie] = useState(props.veggie);
  const [takespart, setTakesPart] = useState(props.takespart);
  const [sleigh, setSleigh] = useState(props.sleigh);
  const { t } = useTranslation();
 
  useEffect(() => {
    setName(props.name);
    setVeggie(props.veggie);
    setTakesPart(props.takespart);
    setSleigh(props.sleigh);

  }, [props.name, props.veggie, props.takespart, props.sleigh]);

  function handleNameChange(event) {
    const newName = event.target.value;
    setName(newName);
    props.onValueChange(props.id, newName, veggie, takespart, sleigh);
  }
  function handleVeggieChange(event) {
    const newVeggie = event.target.checked;
    setVeggie(newVeggie);
    props.onValueChange(props.id, name, newVeggie, takespart, sleigh);
  }
  function handleTakesPartChange(event) {
    const newTakesPart = event.target.checked;
    setTakesPart(newTakesPart);   
    props.onValueChange(props.id, name, veggie, newTakesPart, sleigh);
  }
  function handleSleighChange(event) {
    const newSleigh = event.target.checked;
    setTakesPart(newSleigh);
    props.onValueChange(props.id, name, veggie, takespart, newSleigh);
  }

  return (
    <>
      <input type='hidden' id='id' value={props.id} />
      <div className={classes.formGroup}>     
        <input type='text' id='name' value={name} onChange={handleNameChange} readOnly/>       
      </div>  
      <div className={classes.formGroup + " " + classes.checkBoxes}>      
        <label htmlFor={props.id + 'takespart'} className={classes.checkboxLabel}>{t('registration.takesPart')}:</label>
        <input type='checkbox' id={props.id + 'takespart'} checked={takespart} onChange={handleTakesPartChange} /> 
        <label htmlFor={props.id + 'sleigh'} className={classes.checkboxLabel}>{t('registration.sleigh')}:</label>
        <input type='checkbox' id={props.id + 'sleigh'} checked={sleigh} onChange={handleSleighChange} />  
        <label htmlFor={props.id + 'veggie'} className={classes.checkboxLabel}>{t('registration.veggie')}:</label>
        <input type='checkbox' id={props.id + 'veggie'} checked={veggie} onChange={handleVeggieChange} />
      </div>     

    </>
  );
}

export default Participant;