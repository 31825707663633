import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Flag from "./Flag";
import headerImg from "../images/winterhochzeit.jpg";
import Countdown from "react-countdown";
import classes from "./HeaderAndNav.module.css";
import { useTranslation } from "react-i18next";

function HeaderAndNav(props) {
  const { t, i18n } = useTranslation();
  const [menuIsActive, setMenuActive] = useState(false);
  const Completionist = () => <span>{t("weddingTime")}</span>;
  const targetDate = "2024-03-02T10:30:00";

  const lngs = {
    gb: { nativeName: "English" },
    de: { nativeName: "Deutsch" },
    it: { nativeName: "Italiano" },
  };

  const toggleNavigation = () => {
    setMenuActive(!menuIsActive);
  };

  const closeNavigation = () => {
    setMenuActive(false);
  };

  // Renderer callback with condition
  const dateRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div className={classes.countdown}>
          {days} {t("days")} {hours} {t("hours")} {minutes} {t("minutes")}{" "}
          {seconds} {t("seconds")}
        </div>
      );
    }
  };

  const mainNaviLinks = [
    {
      title: t("wedding.navi"),
      link: "#wedding",
    },
    {
      title: t("faq.navi"),
      link: "#faq",
    },
    {
      title: t("weddingCouplesStay.navi"),
      link: "#weddingCouplesStay",
    },
    {
      title: t("thingsToDo.navi"),
      link: "#thingsToDo",
    },
    {
      title: t("groomsmen.navi"),
      link: "#groomsmen",
    }
  ];

  return (
    <>
      <nav>
        <div className={classes.navigation}>
          <span className={classes.navigationToggle} onClick={toggleNavigation}>
            &#9776;
          </span>
          <ul
            className={(menuIsActive ? "active" : "") + classes.navigationList}      
          >
            <li key="uniqueId1" className={classes.navigationItem}>
              {Object.keys(lngs).map((lng) => (
                <button
                  key={lng}
                  type="submit"
                  onClick={() => i18n.changeLanguage(lng)}
                  className={classes.flags}
                >
                  <Flag countryCode={lng} />
                </button>
              ))}
            </li>            
            {!props.showHeader && (
              <li
                key="uniqueId3"
                className={classes.navigationItem}
                onClick={closeNavigation}
              >
                <NavLink to="/" className={classes.navigationLink}>
                  Home
                </NavLink>
              </li>
            )}
            {mainNaviLinks.map((item, i) => (
              <li
                key={i}
                className={classes.navigationItem}
                onClick={closeNavigation}
              >
                <a className={classes.navigationLink} href={item.link}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      {props.showHeader && (
        <header className={classes.headerContainer}>
          <img
            src={headerImg}
            className={classes.headerImg}
            alt="Hochzeitsbild"
          />
          <div className={classes.headerText}>
            <div className={classes.date}>{t("weddingDate")}</div>
            <h1>Andrea ❤ Susanne</h1>
            <Countdown date={targetDate} renderer={dateRenderer} />
          </div>
        </header>
      )}
    </>
  );
}

export default HeaderAndNav;

/*


            {props.showHeader && (
              <li
                key="uniqueId2"
                className={classes.navigationItem}
                onClick={closeNavigation}
              >
                <NavLink to="/registration" className={classes.navigationLink}>
                  {t("registration.navi")}
                </NavLink>
              </li>
            )}
*/