import React, { useState, useEffect } from "react";
import classes from "./ContentSection.module.css";
import FaqSectionItem from "./FaqSectionItem";
import { useTranslation } from "react-i18next";

function FaqSection(props) {
  const { t, i18n } = useTranslation();
  const [sectionItems, updateSectionItems] = useState([]);

  useEffect(() => {
    switch (props.id) {
      case "faq":
        updateSectionItems([
          {   
            title: t("faq.faq1.title"),
            description: t("faq.faq1.description"),
            link: "",
          },          
          {
            title: t("faq.faq2.title"),
            description: t("faq.faq2.description"),
            link: "https://maps.app.goo.gl/wvA4tPbxXtCc7NQQ9",
          },
          {
            title: t("faq.faq6.title"),
            description: t("faq.faq6.description"),
            link: "",
          },
          {            
            title: t("faq.faq3.title"),
            description: t("faq.faq3.description"),
            link: "",
          },
          {            
            title: t("faq.faq4.title"),
            description: t("faq.faq4.description"),
            link: "",
          },
          {            
            title: t("faq.faq5.title"),
            description: t("faq.faq5.description"),
            link: "",
          }
        ]);
        break;
      default:
    }
  }, [props.language, props.id, t]);

  return (
    <section id={props.id}>
      <h2>{props.title}</h2>
      <div className={classes.faq}>
        {sectionItems.map((item, i) => (
          item.title &&
          <FaqSectionItem
            key={i}
            id={props.id}
            language={i18n.language}
            title={item.title}
            description={item.description}
            link={item.link && item.link} 
          />
        ))}
      </div>
    </section>
  );
}

export default FaqSection;
