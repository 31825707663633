import React from 'react';
import Home from './page/Home';
import Registration from './page/Registration';
import Upload from './page/Upload';
import Imprint from './page/Imprint';
import { BrowserRouter as Router} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';

function App() {
  return (
    <Router>                              
      <Routes>                                                                        
          <Route path="/" element={<Home/>}/>        
          <Route path="/images" element={<Upload/>}/>
          <Route path="/registration" element={<Registration/>}/>
          <Route path="/imprint" element={<Imprint/>}/>
      </Routes>                  
    </Router>
  );
}
 
export default App;