import React, { useState, useEffect } from "react";
import classes from "./ContentSection.module.css";
import ContentSectionItem from "./ContentSectionItem";
import { useTranslation } from "react-i18next";
import registryOfficeImg from "../images/standesamt.jpg";
import registryOfficeImgSmall from "../images/standesamt300.jpg";
import sleighRideImg from "../images/schlittenfahrt.jpg";
import sleighRideImgSmall from "../images/schlittenfahrt300.jpg";
import locationImg from "../images/location.jpg";
import locationImgSmall from "../images/location300.jpg";
import skiingImg1 from "../images/skifoan.jpg";
import skiingImg1Small from "../images/skifoan300.jpg";
import skiingImg2 from "../images/skifoan2.jpg";
import skiingImg2Small from "../images/skifoan2300.jpg";
import barbecueImg from "../images/grill.jpg";
import barbecueImgSmall from "../images/grill300.jpg";
import witness1Img from "../images/groomsmen/cecco.jpg";
import witness2Img from "../images/groomsmen/zwerg_.jpg";
import witness3Img from "../images/groomsmen/massi2.jpg";
import toDoOneImg from "../images/pragserwildsee.jpg";
import toDoOneImgSmall from "../images/pragserwildsee300.jpg";
import toDoTwoImg from "../images/cortina.jpg";
import toDoTwoImgSmall from "../images/cortina300.jpg";
import toDoThreeImg from "../images/malgamisurina.jpg";
import toDoThreeImgSmall from "../images/malgamisurina300.jpg";
import toDoFourImg from "../images/skimisurina.jpg";
import toDoFourImgSmall from "../images/skimisurina300.jpg";
import toDoFiveImg from "../images/spar.jpeg";
import toDoFiveImgSmall from "../images/spar300.jpg";
import toDoSixImg from "../images/3zinnent.JPG";
import toDoSixImgSmall from "../images/3zinnent300.jpg";


function ContentSection(props) {
  const { t, i18n } = useTranslation();
  const [sectionItems, updateSectionItems] = useState([]);
  const [specialClassName, updateSpecialClassName] = useState("");

  useEffect(() => {
    switch (props.id) {
      case "wedding":
        updateSectionItems([
          {
            image: registryOfficeImg,
            imageSmall: registryOfficeImgSmall,
            title: t("wedding.registryOffice.title"),
            description: t("wedding.registryOffice.description"),
            mapsLink: "https://maps.app.goo.gl/1zfLfRmLqbftb2Xv5",
          },
          {
            image: sleighRideImg,
            imageSmall: sleighRideImgSmall,
            title: t("wedding.sleighRide.title"),
            description: t("wedding.sleighRide.description"),
            mapsLink: "https://maps.app.goo.gl/PzPBkcH3tjAZqms3A",
          },
          {
            image: locationImg,
            imageSmall: locationImgSmall,
            title: t("wedding.location.title"),
            description: t("wedding.location.description"),
            mapsLink: "https://goo.gl/maps/ugUq9uFTrd1P97Do8",
          },
        ]);
        break;
      case "weddingCouplesStay":
        updateSectionItems([
          {
            image: skiingImg1,
            imageSmall: skiingImg1Small,
            title: t("weddingCouplesStay.skiingDayOne.title"),
            description: t("weddingCouplesStay.skiingDayOne.description"),
            mapsLink: "",
          },
          {
            image: registryOfficeImg,
            imageSmall: registryOfficeImgSmall,
            title: t("weddingCouplesStay.weddingDay.title"),
            description: t("weddingCouplesStay.weddingDay.description"),
            mapsLink: "",
          },
          {
            image: barbecueImg,
            imageSmall: barbecueImgSmall,
            title: t("weddingCouplesStay.barbecue.title"),
            description: t("weddingCouplesStay.barbecue.description"),
            mapsLink: "https://goo.gl/maps/qHokiPLGjdSzyfVZA",
          },
          {
            image: skiingImg2,
            imageSmall: skiingImg2Small,
            title: t("weddingCouplesStay.skiingDayTwo.title"),
            description: t("weddingCouplesStay.skiingDayTwo.description"),
            mapsLink: "",
          },
        ]);
        break;
      case "groomsmen":
        updateSpecialClassName(classes.groomsmen);
        updateSectionItems([
          {
            image: witness2Img,
            imageSmall: witness2Img,
            title: t("groomsmen.groomsmenTwo.title"),
            description: t("groomsmen.groomsmenTwo.description"),
            mapsLink: "",
          },
          {
            image: witness1Img,
            imageSmall: witness1Img,
            title: t("groomsmen.groomsmenOne.title"),
            description: t("groomsmen.groomsmenOne.description"),
            mapsLink: "",
          },
          {
            image: witness3Img,
            imageSmall: witness3Img,
            title: t("groomsmen.groomsmenThree.title"),
            description: t("groomsmen.groomsmenThree.description"),
            mapsLink: "",
          }
        ]);
        break;
      case "thingsToDo":
        updateSectionItems([
          {
            link: "https://goo.gl/maps/AiCzU5w8Rm3iJHhr5",
            image: toDoOneImg,
            imageSmall: toDoOneImgSmall,
            title: t("thingsToDo.toDoOne.title"),
            description: t("thingsToDo.toDoOne.description"),
            mapsLink: "https://goo.gl/maps/AiCzU5w8Rm3iJHhr5",
          },
          {
            link: "https://goo.gl/maps/HeajjmyNm2BEwWNE9",
            image: toDoTwoImg,
            imageSmall: toDoTwoImgSmall,
            title: t("thingsToDo.toDoTwo.title"),
            description: t("thingsToDo.toDoTwo.description"),
            mapsLink: "https://goo.gl/maps/HeajjmyNm2BEwWNE9",
          },
          {
            link: "https://goo.gl/maps/2vBkVKU6xwdegDa77",
            image: toDoThreeImg,
            imageSmall: toDoThreeImgSmall,
            title: t("thingsToDo.toDoThree.title"),
            description: t("thingsToDo.toDoThree.description"),
            mapsLink: "https://goo.gl/maps/2vBkVKU6xwdegDa77",
          },
          {
            link: "https://auronzomisurina.it/area-sci-misurina/",
            image: toDoFourImg,
            imageSmall: toDoFourImgSmall,
            title: t("thingsToDo.toDoFour.title"),
            description: t("thingsToDo.toDoFour.description"),
            mapsLink: "https://maps.app.goo.gl/WFpRgr2dMtLZE6Zt5",
          },
          {
            link: "https://maps.app.goo.gl/3XkB51M3NKF1Tb4o7",
            image: toDoFiveImg,
            imageSmall: toDoFiveImgSmall,
            title: t("thingsToDo.toDoFive.title"),
            description: t("thingsToDo.toDoFive.description"),
            mapsLink: "https://maps.app.goo.gl/3XkB51M3NKF1Tb4o7",
          },
          {
            link: "https://maps.app.goo.gl/Q68eoFbtrPQ3uGmh9",
            image: toDoSixImg,
            imageSmall: toDoSixImgSmall,
            title: t("thingsToDo.toDoSix.title"),
            description: t("thingsToDo.toDoSix.description"),
            mapsLink: "https://maps.app.goo.gl/Q68eoFbtrPQ3uGmh9",
          },
        ]);
        break;
      default:
    }
  }, [props.language, props.id, t]);

  return (
    <section id={props.id}>
      <h2>{props.title}</h2>
      <p>{props.intro}</p>
      <div className={classes.highlightContainer + " " + specialClassName}>
        {sectionItems.map((item, i) => (
          <ContentSectionItem
            key={i}
            id={props.id}
            language={i18n.language}
            link={item.link && item.link}
            image={item.image}
            imageSmall={item.imageSmall}
            title={item.title}
            description={item.description}
            mapsLink={item.mapsLink}
          />
        ))}
      </div>
    </section>
  );
}

export default ContentSection;
