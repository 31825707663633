import React, {useEffect} from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./Footer.module.css";

function Footer(props) {
  const { t } = useTranslation();

  useEffect(() => {
  }, [props.language]);

  return (
    <footer>
      <p>
        &copy; 2023 Susanne & Andrea - {" "}
        <NavLink to="/imprint" target="_blank">
          {t('imprint')}
        </NavLink>
      </p>
    </footer>
  );
}

export default Footer;
