import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update, onValue } from "firebase/database";
import EditParticipants from "../components/Registration/EditParticipants";
import Login from "../components/Registration/Login";
import Logout from "../components/Registration/Logout";
import HeaderAndNav from "../components/HeaderAndNav";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import classes from '../components/Registration/Form.module.css';
import ShowData from "../components/Registration/ShowData";
import { NavLink } from "react-router-dom";

const Registration = () => {
  const { t, i18n } = useTranslation();
  const [showLogin, setShowLogin] = useState(true);
  const [userDataFromDb, setUserDataFromDb] = useState([]);
  const [authUserId, setAuthUserId] = useState();
  const db = getDatabase();
  const [isSaved, setIsSaved] = useState(false);
  const [isSubmmitted, setIsSubmmitted] = useState(false);

  useEffect(() => {
    if (authUserId) {
      const userRef = ref(db, "users/"  + authUserId   ); //+ authUserId  Tr8BJFrrw7StAswnuCyVs0XglJq1
      onValue(userRef, (snapshot) => {
        const snapshotData = snapshot.val();
        // Fetch participants
        const snapshotParticipants = snapshotData.participants || {};;
        const participantsDataFetched = [];  
        Object.keys(snapshotParticipants).forEach((participantKey) => {
          const participant = snapshotParticipants[participantKey];
          participantsDataFetched.push({
            id: participantKey,
            name: participant.name,
            veggie: participant.veggie,
            takespart: participant.takespart,
            sleigh: participant.sleigh
          });    
        });
        setUserDataFromDb({
          participants: participantsDataFetched,
          nights: snapshotData.nights || '',
          friday: snapshotData.friday || false,
          saturday: snapshotData.saturday || false,
          sunday: snapshotData.sunday || false,
          monday: snapshotData.monday || false,
          bbq: snapshotData.bbq || false,
          free: snapshotData.free || false,
          welcome: snapshotData.welcome || '',
          title: snapshotData.title || '',
          notes: snapshotData.notes || '',
          song1: snapshotData.song1 || '',
          song2: snapshotData.song2 || '',
          song3: snapshotData.song3 || ''
        }); 

      });
    }
  }, [authUserId, db]);

  const authStateHandler = (user) => {
    if (user) {
      setAuthUserId(user.uid);
      setShowLogin(false);
    } else {
      setAuthUserId();
      setShowLogin(true);
    }
  };

  async function addUserDataHandler(userData) {
    const participantsUpdates = {};
    const userUpdates = {};
    setIsSubmmitted(true);

    userUpdates[
      "users/" + authUserId 
    ] = {
      friday: userData.friday,
      saturday: userData.saturday,
      sunday: userData.sunday,
      monday: userData.monday,
      bbq: userData.bbq,
      free: userData.free,
      welcome: userData.welcome,
      title: userData.title,
      nights: userData.nights,
      notes: userData.notes,
      song1: userData.song1,
      song2: userData.song2,
      song3: userData.song3
    };

    userData.participants.forEach((participant) => {
      const newParticipantKey = participant.id;
      const { id, ...participantWithoutId } = participant;
      participantsUpdates[
        "users/" + authUserId + "/participants/" + newParticipantKey
      ] = participantWithoutId;
    }); 

    try {
      const updatePromises = [];
  
      updatePromises.push(update(ref(db), userUpdates));
  
      if (Object.keys(participantsUpdates).length > 0) {
        updatePromises.push(update(ref(db), participantsUpdates));
      }  
      await Promise.all(updatePromises);
      setIsSaved(true);
    } catch (error) {
      console.error("Error updating database:", error);
      setIsSaved(false);
    }
    /*
    update(ref(db), userUpdates);
    update(ref(db), participantsUpdates);
    */
  }

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setAuthUserId(uid);
        setShowLogin(false);     
      } else {
        setAuthUserId(null); 
        setShowLogin(true);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const editDataHandler = () => {
      setIsSubmmitted(false);
  }

  return (
    <>
      <HeaderAndNav showHeader={false} />
      <main className={ (authUserId !== "Tr8BJFrrw7StAswnuCyVs0XglJq1") ? classes.mainRegistration : classes.showData}>      
        <h1>{t("registration.navi")}</h1>
        {showLogin ? (
          <Login onLogin={authStateHandler} language={i18n.language} />
        ) : (
          <>     
          <Logout onUserLogout={authStateHandler} language={i18n.language} text={t("registration.logout")}/>   
          <p>{t("registration.moreInfoP")} <NavLink to="/" className={classes.navigationLink}>&raquo; Home &laquo;</NavLink></p>                  
           {(authUserId === "Tr8BJFrrw7StAswnuCyVs0XglJq1") ?
            <ShowData/> :
            <EditParticipants
              userData={userDataFromDb}
              onAddUserData={addUserDataHandler}
              language={i18n.language}
              onEditData={editDataHandler}
            />        
           }          
           { (isSubmmitted && isSaved) && ( 
            <div className={classes.success}>
              {t("registration.saved")}
              <Logout onUserLogout={authStateHandler} language={i18n.language} text=""/>               
            </div>
            )
           }   
            { (isSubmmitted && !isSaved) && ( 
            <div className={classes.error}>
              {t("registration.errorSaved")}           
            </div> 
            )
           }       
          </>
        )}        
      </main>
      <Footer language={i18n.language} />
    </>
  );
};

export default Registration;
