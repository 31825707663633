import React, { useState, useEffect } from "react";
import classes from "./Form.module.css";
import Participant from "./Participant";
import { useTranslation } from "react-i18next";

function EditParticipants(props) {
  const [participantsData, setParticipantsData] = useState([]);
  const [free, setFree] = useState(false);
  const [welcome, setWelcome] = useState("");
  const [title, setTitle] = useState("");
  const [nights, setNights] = useState(0);
  const [friday, setFriday] = useState(true);
  const [saturday, setSaturday] = useState(true);
  const [sunday, setSunday] = useState(true);
  const [monday, setMonday] = useState(true);
  const [bbq, setBbq] = useState(true);
  const [notes, setNotes] = useState("");
  const [song1, setSong1] = useState("");
  const [song2, setSong2] = useState("");
  const [song3, setSong3] = useState("");
  const { t } = useTranslation();

  // Initialisierung mit den Daten aus props.userData
  useEffect(() => {
    if (props.userData && props.userData.participants) {
      const updatedParticipantsData = props.userData.participants.map(
        (participant) => ({
          id: participant.id,
          name: participant.name,
          veggie: participant.veggie ? true : false,
          takespart: participant.takespart ? true : false,
          sleigh: participant.sleigh ? true : false,
        })
      );

      setFriday(props.userData.friday);
      setSaturday(props.userData.saturday);
      setSunday(props.userData.sunday);
      setMonday(props.userData.monday);
      setBbq(props.userData.bbq);
      setFree(props.userData.free);
      setWelcome(props.userData.welcome);
      setTitle(props.userData.title);
      setNights(props.userData.nights);
      setNotes(props.userData.notes);
      setSong1(props.userData.song1);
      setSong2(props.userData.song2);
      setSong3(props.userData.song3);
      setParticipantsData(updatedParticipantsData);
    }
  }, [props.userData, props.language]);

  function handleParticipantChange(id, name, veggie, takespart, sleigh) {
    props.onEditData();
    // Aktualisiere die Daten für den betreffenden Teilnehmer
    const updatedParticipantsData = participantsData.map((participant) =>
      participant.id === id
        ? { ...participant, name, veggie, takespart, sleigh }
        : participant
    );

    setParticipantsData(updatedParticipantsData);
  }

  function submitHandler(event) {
    event.preventDefault();

    props.onAddUserData({
      participants: participantsData,
      notes: notes,
      free: free ? true : false,
      title: title,
      welcome: welcome,
      friday: friday,
      saturday: saturday,
      sunday: sunday,
      monday: monday,
      bbq: bbq,
      nights: nights,
      song1: song1,
      song2: song2,
      song3: song3,
    });
  }

  return (
    <div className={classes.registration}>
      <p className={classes.greetings}>{welcome && welcome}</p>
      <form onSubmit={submitHandler} className={classes.registrationForm}>
        <div className={classes.formContainer}>
          <h2>{t("registration.guests")}</h2>
          {participantsData.map((participant) => (
            <Participant
              key={participant.id}
              id={participant.id}
              name={participant.name}
              veggie={participant.veggie}
              takespart={participant.takespart}
              sleigh={participant.sleigh}
              onValueChange={handleParticipantChange}
            />
          ))}
        </div>
        <div className={classes.formContainer + " " + classes.formMoreInfos}>
          <h2>{t("registration.moreInfo")}</h2>
          <div className={classes.formGroup + " " + classes.checkBoxes}>
            <label htmlFor="bbq">{t("registration.bbq")}:</label>
            <input
              type="checkbox"
              id="bbq"
              checked={bbq}
              onChange={(e) => {
                props.onEditData();
                setBbq(e.target.checked);
              }}
            />
          </div>
          <div className={classes.formGroup + " " + classes.checkBoxes}>
            <div className={classes.formGroupHeadline}>          
              {t("registration.quantityNights")}:{" "}
              <small>
                <br />
                {!free && "(" + t("registration.costsPerNight") + ")"}
              </small>           
              <input
                type="hidden"
                inputMode="numeric"
                id="nights"
                min="0"
                max="4"
                autoComplete="off"
                name="nights"
                value={nights}
                onChange={(e) => {
                  props.onEditData();
                  setNights(e.target.value);
                }}
              />
            </div>      
            <label htmlFor="friday">{t("registration.friday")}:</label>
            <input
              type="checkbox"
              id="friday"
              checked={friday}
              onChange={(e) => {
                props.onEditData();
                setFriday(e.target.checked);
              }}
            />

            <label htmlFor="saturday">{t("registration.saturday")}:</label>
            <input
              type="checkbox"
              id="saturday"
              checked={saturday}
              onChange={(e) => {
                props.onEditData();
                setSaturday(e.target.checked);
              }}
            />

            <label htmlFor="sunday">{t("registration.sunday")}:</label>
            <input
              type="checkbox"
              id="sunday"
              checked={sunday}
              onChange={(e) => {
                props.onEditData();
                setSunday(e.target.checked);
              }}
            />

            <label htmlFor="monday">{t("registration.monday")}:</label>
            <input
              type="checkbox"
              id="monday"
              checked={monday}
              onChange={(e) => {
                props.onEditData();
                setMonday(e.target.checked);
              }}
            />
          </div>
     

          <div className={classes.formGroup}>
            <label htmlFor="song1">{t("registration.songsUlike")}:</label>
            <input
              type="text"
              id="song1"
              value={song1}
              onChange={(e) => {
                props.onEditData();
                setSong1(e.target.value);
              }}
            />
            <input
              type="text"
              id="song2"
              value={song2}
              onChange={(e) => {
                props.onEditData();
                setSong2(e.target.value);
              }}
            />
            <input
              type="text"
              id="song3"
              value={song3}
              onChange={(e) => {
                props.onEditData();
                setSong3(e.target.value);
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <label htmlFor="notes">{t("registration.notes")}:</label>
            <textarea
              rows="2"
              id="notes"
              value={notes}
              placeholder={t("registration.placeholder")}
              onChange={(e) => {
                props.onEditData();
                setNotes(e.target.value);
              }}
            ></textarea>
          </div>
        </div>
        <button>{t("registration.save")}</button>
      </form>
    </div>
  );
}

export default EditParticipants;
