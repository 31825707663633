// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyD_up24BJkXG_prRsgcBO8hgQ2HMxspDRg",
  authDomain: "wedding-bs-368ca.firebaseapp.com",
  databaseURL: "https://wedding-bs-368ca-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wedding-bs-368ca",
  storageBucket: "wedding-bs-368ca.appspot.com",
  messagingSenderId: "996787996442",
  appId: "1:996787996442:web:22764040c09c10491d4ea3",
  measurementId: "G-FLK0M756BB"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const storage = getStorage(app);
export default app;
