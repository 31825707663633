import React from "react";
import HeaderAndNav from "../components/HeaderAndNav";
import classes from '../components/Registration/Form.module.css';

const Imprint = () => {
  return (
    <>
      <HeaderAndNav showHeader={false} />
      <main className={classes.imprint}>  
        <h1>Impressum</h1>
        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>
          Susanne Krieg
          <br />
          Eschenbachstra&szlig;e 1<br />
          81549 M&uuml;nchen
        </p>
        <h2>Kontakt</h2>
        <p>
          Telefon: +4915140771629
          <br />
          E-Mail: kriegsusi@gmail.com
        </p>
        <h2>Haftung für Links</h2>
        <p>
          Trotz sorgfältiger inhaltlicher Kontrolle übernehme ich keine Haftung für die Inhalte externer Links.
          Für den Inhalt sind ausschließlich deren Betreiber verantwortlich.
        </p>
        <h2>Haftung für Inhalte</h2>
        <p>
          Die Inhalte unserer Seiten werden mit größter Sorgfalt erstellt. Für die Richtigkeit,
          Vollständigkeit und Aktualität der Inhalte kann ich jedoch keine Gewähr übernehmen.
        </p>
        <h2>Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
          Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
          bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. 
        </p>
      </main>
    </>
  );
};

export default Imprint;
