import React from "react";
import { auth } from "../../firebase";
import classes from "./Form.module.css";
import { useNavigate } from "react-router-dom";

const Logout = (props) => {
  const navigate = useNavigate();

  const onLogout = (e) => {
    e.preventDefault();
    auth.signOut();
    props.onUserLogout(false);
    navigate("/");
  };

  return (
    <div className={classes.logoutSpan}>
      {props.text} <span onClick={onLogout}>&raquo; Logout &laquo;</span>      
    </div>
  );
};

export default Logout;
