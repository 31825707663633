import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import classes from "./Form.module.css";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("");
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        setUser(userCredential.user);
      })
      .catch((error) => {
        /*const errorCode = error.code;
        const errorMessage = error.message;*/
        setError(true);
      });

    props.onLogin(user);
  };

  useEffect(() => {}, [props.language]);

  return (
    <div className={error ? classes.error : undefined}>
      <form className={classes.registrationForm}>
        {error && <div className={classes.errorText}>{t("registration.error")}</div>}
        <div className={classes.formGroup}>
          <label htmlFor="email-address">{t("registration.email")}</label>
          <input
            id="email-address"
            name="email"
            type="email"
            required
            placeholder={t("registration.email")}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className={classes.formGroup}>
          <label htmlFor="password">{t("registration.password")}</label>
          <input
            id="password"
            name="password"
            type="password"
            required
            placeholder={t("registration.password")}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className={classes.formGroup}>
          <button onClick={onLogin}>Login</button>
        </div>
      </form>
    </div>
  );
};

export default Login;
