import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";

function ShowData() { 
  const [usersDataFromDb, setUsersDataFromDb] = useState([]);
  const db = getDatabase();

  useEffect(() => {
    const userRef = ref(db, "users/");
    onValue(userRef, (snapshot) => {
      
      try {
      const snapshotData = snapshot.val();
      
      // Fetch user data and format it for display
      const usersDataFetched = [];
      for (const userId in snapshotData) {
        const user = snapshotData[userId];
        const participants = user.participants || [];
        const participantsDataFetched = participants.map((participant) => ({
          name: participant.name,
          veggie: participant.veggie,
          takespart: participant.takespart,
          sleigh: participant.sleigh
        }));

        usersDataFetched.push({
          id: userId,
          nights: user.nights || '',
          friday: user.friday || false,
          saturday: user.saturday || false,
          sunday: user.sunday || false,
          monday: user.monday || false,
          bbq: user.bbq || false,
          free: user.free || false,
          welcome: user.welcome || '',
          title: user.title || '',
          notes: user.notes || '',
          song1: user.song1 || '',
          song2: user.song2 || '',
          song3: user.song3 || '',
          participants: participantsDataFetched
        });
      } 
        setUsersDataFromDb(usersDataFetched);
      } catch (error) {
        console.error("Error in Firebase callback:", error);
      }
    });
  }, [db]);


return (

    <table>
      <thead>
        <tr>
          <th>Titel</th>
          <th>Nächte</th>
          <th>BBQ</th>
          <th>Notizen</th>
          <th>Song #1</th>
          <th>Song #2</th>
          <th>Song #3</th>
        </tr>
      </thead>
      <tbody>
        {usersDataFromDb.map((user) => (
              <React.Fragment key={user.id}>
              <tr>   
                <td rowSpan={user.participants.length +2}>{user.title}</td>   
                <td rowSpan={user.participants.length +2}>{           
                  (user.friday ? " FR" : "") 
                  + (user.saturday ? " SA" : "") 
                  + (user.sunday ? " SO" : "") 
                  + (user.monday ? " MO" : "") 
                }</td>
                <td rowSpan={user.participants.length +2}>{user.bbq ? "✓" : "X"}</td>
                <td rowSpan={user.participants.length +2}>{user.notes}</td>
                <td rowSpan={user.participants.length +2}>{user.song1}</td>
                <td rowSpan={user.participants.length +2}>{user.song2}</td>
                <td rowSpan={user.participants.length +2}>{user.song3}</td>
              </tr>
              <tr>
                <th>Name</th>
                <th>Dabei</th>
                <th>Schlitten</th>
                <th>Veggie</th>
              </tr>
              {user.participants.map((participant, participantIndex) => (
                <tr key={`${user.id}-participant-${participantIndex}`}>   
                  <td>{participant.name}</td>          
                  <td>{participant.takespart ? "✓" : "X"}</td>                 
                  <td>{participant.sleigh ? "✓" : "X"}</td>       
                  <td>{participant.veggie ? "✓" : "X"}</td>              
                </tr>
              ))}
            </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="7">Gesamt</td>
          <td></td>      
          <td>{usersDataFromDb.reduce((total, user) => total + user.participants.filter(participant => participant.takespart).length, 0)}</td>
          <td>{usersDataFromDb.reduce((total, user) => total + user.participants.filter(participant => participant.sleigh).length, 0)}</td>
          <td>{usersDataFromDb.reduce((total, user) => total + user.participants.filter(participant => participant.veggie).length, 0)}</td>
        </tr>
      </tfoot>
    </table>

);

}

export default ShowData;
