import React, {useState, useEffect} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import HeaderAndNav from "../components/HeaderAndNav";
import Footer from "../components/Footer";
import ContentSection from "../components/ContentSection";
import FaqSection from "../components/FaqSection";
import { useTranslation, withTranslation } from "react-i18next";
import UploadSection from "../components/UploadSection";

const Home = () => {
  const { t, i18n } = useTranslation();
  /* will be used for groomsmen section */
    const [authUser, setAuthUser] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(true);
      } else {
        //console.log("user is logged out");
      }
    });
    const intervalID = setInterval(() => {}, 1000);
    return () => clearInterval(intervalID);
  }, []);
 

  return (
    <>
      <HeaderAndNav showHeader={true} language={i18n.language}/>
      <main>       
        <ContentSection
          language={i18n.language}
          id="wedding"
          title={t("wedding.headline")}
          intro={t("wedding.description")}
        />
        <UploadSection />
        <FaqSection
          language={i18n.language}
          id="faq"
          title={t("faq.title")}
        />
        <ContentSection
          language={i18n.language}
          id="weddingCouplesStay"
          title={t("weddingCouplesStay.headline")}
          intro={t("weddingCouplesStay.description")}
        />        
        <ContentSection
          language={i18n.language}
          id="thingsToDo"
          title={t("thingsToDo.headline")}
          intro={t("thingsToDo.description")}
        />
       <ContentSection
          language={i18n.language}
          id="groomsmen"
          title={t("groomsmen.headline")}
          intro={t("groomsmen.description")}
        />
      </main>
      <Footer language={i18n.language} />
    </>
  );
};

export default withTranslation()(Home);
