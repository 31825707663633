import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  ref as refS,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  getMetadata,
  listAll,
  deleteObject
} from "firebase/storage";
import Login from "../components/Registration/Login";
import Logout from "../components/Registration/Logout";
import HeaderAndNav from "../components/HeaderAndNav";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import classes from "../components/Registration/Form.module.css";

const Upload = () => {
  const { t, i18n } = useTranslation();
  const [showLogin, setShowLogin] = useState(true);
  const [authUserId, setAuthUserId] = useState();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]); // Zustandsvariable für hochgeladene Bilder
  const storage = getStorage();
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [inputKey, setInputKey] = useState(Date.now());

  const getOriginalFile = (fileName, uploaded, dimensions = '400x400') => {
    if(uploaded === false){ // remove dimensions
      const sizeIndex = fileName.lastIndexOf('_'+ dimensions);
      const extIndex = fileName.lastIndexOf('.');
      const ext = fileName.substring(extIndex);
      return `${fileName.substring(0, sizeIndex)}${ext}`;
    } else {
      return fileName;
    } 
  }


  const authStateHandler = (user) => {
    if (user) {
      setAuthUserId(user.uid);
      setShowLogin(false);
    } else {
      setAuthUserId();
      setShowLogin(true);
    }
  };

  const handleChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleUpload = () => {
    if (files.length === 0) {
      alert("Please upload at least one file!");
      return;
    }
    setLoading(true); // Setze den Ladezustand auf true

    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    let totalBytesUploaded = 0;

    const uploadPromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const storageRef = refS(storage, `/files/${authUserId}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            totalBytesUploaded += snapshot.bytesTransferred;
            totalBytesUploaded = Math.min(totalBytesUploaded, totalSize);
            
          },
          reject, // Fehlerbehandlung
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => {
                resolve(url); // Erfolgreiches Hochladen der Datei
              })
              .catch(reject); // Fehlerbehandlung
          }
        );
      });
    });

    Promise.all(uploadPromises)
      .then((urls) => {
        // Erstelle ein Array von Bildobjekten mit URL und Erstellungszeitpunkt
        const newImages = urls.map((url) => ({ url, timeCreated: Date(), uploaded: true }));
        setUploadedImages((prevImages) => [...newImages, ...prevImages]); // Füge die neuen Bilder zu uploadedImages hinzu
        setLoading(false); // Setze isLoading auf false, nachdem alle Dateien hochgeladen wurden
        setInputKey(Date.now());
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
        setLoading(false); // Setze isLoading auf false, wenn ein Fehler auftritt
      });
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setAuthUserId(uid);
        setShowLogin(false);
      } else {
        setAuthUserId(null);
        setShowLogin(true);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const listUploadedFiles = async () => {
      try {
        const storageRef = refS(storage, "/files/" + authUserId + "/" );
        const fileList = await listAll(storageRef);

        const fileData = await Promise.all(
          fileList.items.map(async (item) => {
            const metadata = await getMetadata(item);
         
            const url = await getDownloadURL(item);
            return { url, timeCreated: metadata.timeCreated, uploaded: false };
          })
        );

        setUploadedImages(fileData.filter(file => file.url.includes('_400x400')));
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
    };

    listUploadedFiles();
  }, [storage, authUserId, reloadTrigger]);

const deleteHandler = (filePath) => {

  const isConfirmed = window.confirm('Do you really want to delete this file?');
  
  if (!isConfirmed) {
    // Benutzer hat den Löschvorgang abgebrochen
    return;
  }

  // Verwende `let` für Variablen, deren Werte geändert werden
  let originalFilePath = "";
  let resizedFilePath = "";

  if(filePath.includes('_400x400')) {
    originalFilePath = getOriginalFile(filePath, false); // Entferne 200_200 für das Original
    resizedFilePath = filePath;
  } else {
    originalFilePath = filePath;
    const extIndex = filePath.lastIndexOf('.');
    const ext = filePath.substring(extIndex);
    resizedFilePath = `${filePath.substring(0, extIndex)}_400x400${ext}`; // Füge 200_200 für die verkleinerte Version hinzu
    
  }

  // Lösche das Originalbild
  const originalFileRef = refS(storage, originalFilePath);
  const resizedFileRef = refS(storage, resizedFilePath);

   // Verwende Promise.all, um beide Löschvorgänge zu verwalten
   Promise.all([
    deleteObject(originalFileRef),
    deleteObject(resizedFileRef)
  ])
  .then(() => {
    console.log('Beide Dateien erfolgreich gelöscht');
    // Aktualisiere den Trigger für die Neuladung, nachdem beide Dateien gelöscht wurden
    setReloadTrigger(prev => prev + 1);
  })
  .catch((error) => {
    console.error('Fehler beim Löschen der Dateien:', error);
  });
};

  return (
    <>
      <HeaderAndNav showHeader={false} />
      <main
        className={classes.showData}
      >
        <h1>{t("upload.navi")}</h1>
        <p className={classes.description}>{t("upload.download")}</p>
        {!showLogin ? (
           <>
           {
             <div className={classes.registrationForm}>
               <input
                 className={classes.fileUpload}
                 type="file"
                 multiple
                 onChange={handleChange}
                 accept=""
                 key={inputKey}
               />
              <div><button onClick={handleUpload}>{t("upload.button")}</button></div> 
             </div>
           }
           {loading && <div className={classes.loader}></div>}
           {uploadedImages.length > 0 && (
             <div>
               <div className={classes.uploadedImages}>
                 {uploadedImages
                   .sort((a, b) => a.timeCreated < b.timeCreated)
                   .map((image, index) => (
                      <div className={classes.imgDiv} key={index}> 
                        <img                        
                          src={image.url}              
                          
                        />
                       
                          <button className="remove" onClick={() => deleteHandler(image.url)}>{t("upload.delete")}</button>
                        
                     </div>
                   ))}
               </div>
             </div>
           )}
            <Logout
             onUserLogout={authStateHandler}
             language={i18n.language}
             text={t("upload.logout")}
           />
         </>
       
        ) : (
          <Login onLogin={authStateHandler} language={i18n.language} />
        )
         }
      </main>
      <Footer language={i18n.language} />
    </>
  );
};

export default Upload;
