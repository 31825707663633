import React from "react";
import Flags from "country-flag-icons/react/3x2";

const Flag = (props) => {
  const countryCode = props.countryCode.toUpperCase();
  const FlagComponent = Flags[countryCode];
  return <FlagComponent />;
};

export default Flag;
