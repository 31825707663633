import React, { useState, useEffect } from "react";
import classes from "./UploadSection.module.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
    ref as refS,
    getDownloadURL,
    getStorage,
    getMetadata,
    listAll
  } from "firebase/storage";



const UploadSection = (props) => {
  const { t } = useTranslation();
  const storage = getStorage();  
  const [uploadedImages, setUploadedImages] = useState([]); // Zustandsvariable für hochgeladene Bilder
  const [selectedImage, setSelectedImage] = useState(null);
  const getOriginalFile = (fileName, uploaded, dimensions = '400x400') => {
    if(uploaded === false){ // remove dimensions
      const sizeIndex = fileName.lastIndexOf('_'+ dimensions);
      const extIndex = fileName.lastIndexOf('.');
      const ext = fileName.substring(extIndex);
      return `${fileName.substring(0, sizeIndex)}${ext}`;
    } else {
      return fileName;
    } 
  }

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Elemente tauschen
      }
      return array;
    };
  
    const listFilesInFolder = async (path) => {
      const storageRef = refS(storage, path);
      const fileList = await listAll(storageRef);
      let fileData = [];
      
      // Dateien im aktuellen Verzeichnis abrufen
      const files = await Promise.all(
        fileList.items.map(async (item) => {
          const metadata = await getMetadata(item);
          const url = await getDownloadURL(item);
          return { url, timeCreated: metadata.timeCreated, uploaded: false };
        })
      );
  
      fileData = fileData.concat(files);
      
      // Rekursiv durch Unterordner iterieren
      for (const prefix of fileList.prefixes) {
        const subfolderData = await listFilesInFolder(prefix.fullPath);
        fileData = fileData.concat(subfolderData);
      }
  
      return fileData;
    };
  
    const listUploadedFiles = async () => {
      try {
        let fileData = await listFilesInFolder("/files/");
        fileData = fileData.filter(file => file.url.includes('_400x400'));
        fileData = shuffleArray(fileData); // Die Liste mischen
        fileData = fileData.slice(0, 14); // Auf maximal 14 Elemente begrenzen
        setUploadedImages(fileData);
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
    };
  
    listUploadedFiles();
  }, [storage, props.language]);
  
  


  const openModal = (imageUrl, uploaded) => {
    setSelectedImage(getOriginalFile(imageUrl, uploaded));
  };

  
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
 
        <section className={classes.ctaContainer}>
            <button>
                <NavLink to="/images">{t("upload.cta")}</NavLink>
            </button>
      
        {uploadedImages.length > 0 && (     
        
            <div className={classes.uploadedImages}>
                <div className={classes.random}>{t("upload.random")}</div> 
                <div>
                  {uploadedImages
                  .sort((a, b) => a.timeCreated < b.timeCreated)
                  .map((image, index) => (
                    <div className={classes.imgDiv}>
                      <img
                            key={index}                        
                            src={image.url}              
                            onClick={() => openModal(image.url, image.uploaded)}
                        />
                    </div>
                  ))}
                  </div>
                </div>
                   )}
        {selectedImage && (
            <div className={classes.modal} onClick={closeModal}>
                <img
                    src={selectedImage}
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        )}
        
        </section>
  );
};

export default UploadSection;
