import React, { useState, useEffect } from "react";
import classes from "./ContentSectionItem.module.css";
import { useTranslation } from "react-i18next";

function ContentSectionItem(props) {
  const [specialClassName, updateSpecialClassName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    switch (props.id) {
      case "groomsmen":
        updateSpecialClassName(classes.groomsmen);
        break;
      default:
    }
  }, [props.id, props.language]);

  const content = (  
       <>      
       <div className={classes.highlightImage}>
          <img src={props.image} alt={props.title} 
                 srcSet={props.imageSmall + " 768w, " + props.image + " 1280w"}
                 sizes="(max-width: 768px) 768px, 1280px"
          />
      </div>
      <div className={classes.description}>
          <h3>{props.title}</h3>
          <p>{props.description}{(props.mapsLink && 
              <span>
                <br />
                <a href={props.mapsLink} target="_blank" rel="noopener noreferrer">&raquo; {t('mapslink')} &laquo;</a>
              </span>
              )
            }</p>
        </div> 
      </>
  );

  return props.link && props.title ? (
    <a href={props.link} target='_blank' rel="noopener noreferrer" className={classes.highlightItem + " " + specialClassName}>{content}</a>
  ) : props.title && (
    <div className={classes.highlightItem + " " + specialClassName}>
      {content}
    </div>  
  );
  
}

export default ContentSectionItem;